<template>
  <div>
    <div class="pageHeader" style="overflow: hidden">
      <div style="float: left">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handle_add()"
          v-if="addflag"
          >新建</el-button
        >
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()"
          >刷新</el-button
        >
      </div>
      <div style="float: right">
        <el-input
          placeholder="分类名称"
          v-model="keyword"
          class="input-with-select"
          style="width: 250px"
        ></el-input
        >&nbsp;

        <el-button
          type="primary"
          @click="handler_search()"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
    </div>

    <div>
      <el-table
        :data="page.list"
        v-loading="showLoading"
        element-loading-text="拼命加载中"
        border
        row-key="id"
        highlight-current-row
        :tree-props="{ children: 'children', hasChildren: 'hc' }"
        width="width:100%; height:50%"
      >
        <el-table-column type="index" label="顺序" width="50"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="200"
          :formatter="formatterStatus"
        ></el-table-column>

        <el-table-column label="操作" conter width="280">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-plus"
              @click="handle_add(scope.row)"
              v-if="scope.row.parentid == 0"
              >添加</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handle_edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handle_clickenable(scope.row)"
              v-if="scope.row.status==0"
              >上线</el-button
            >
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handle_clickdisable(scope.row)"
              v-if="scope.row.status==1"
              >下线</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handle_del(scope.$index, scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="devlogChange"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="职位分类信息"
      :close-on-click-modal="false"
      width="600px"
      v-model="digShow"
    >
      <el-form ref="form" :model="cuttData" :rules="rules">
        <el-form-item label="父节点" label-width="100px">
          <el-input
            v-model="cuttData.parentName"
            disabled
            style="width: 80%"
          ></el-input>
          <el-button
            icon="el-icon-delete"
            class="red"
            @click="handle_clearParent"
            >删除</el-button
          >
        </el-form-item>
        <el-form-item label="名称" label-width="100px" prop="name">
          <el-input v-model="cuttData.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="digShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handle_validate('form')"
          :disabled="lock"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CATEGORY from "../../api/lianying/api_jobcategory";

export default {
  name: "categorylist", // 分类列表
  props: {
    addflag:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      keyword: "",
      showLoading: false,
      page: { list: [], pageNum: 1, pageSize: 10, total: 0 },
      auth: {},

      lock: false,
      digShow: false,

      cuttData: {
        parentId: undefined,
        name: undefined,
      },

      rules: {
        name: [
          { required: true, message: "请输入职位分类名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  computed: {},
  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },
    

    // 加载页面数据
    initPageData() {
      this.showLoading = true;

      console.log("keyword " + this.keyword);
      CATEGORY.list({
        currentPage: this.page?.pageNum,
        pageSize: this.page?.pageSize,
        keyword: this.keyword,
      }).then((res) => {
        this.showLoading = false;
        console.log("list res " + JSON.stringify(res));
        if (1 == res.status) {
          var data = res?.data;
          this.page.total = data?.totalNumber;
          this.page.list = data?.data;
        } else {
          this.$message.error(res?.message);
          this.page.list = [];
        }
      });
    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },
    handle_clearParent() {
      this.cuttData.parentid = 0;
      this.cuttData.parentName = undefined;
    },
   
    handler_search() {
      this.initPageData();
    },
    handle_validate(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.handle_save();
        } else {
          return false;
        }
      });
    },
    async handle_save() {
      if (this.lock) {
        return;
      }
      this.lock = true;
      console.log('save '+JSON.stringify(this.cuttData))
      CATEGORY.save(this.cuttData).then(this.res_handle);
    },
    handle_add(row) {
      console.log("category " + JSON.stringify(row));
      this.cuttData = {
        add: true,
        parentid:undefined,
        parentName: undefined,
      };
      if (row) {
        this.cuttData.parentid = row.id;
        this.cuttData.parentName=row.name;
      }
      this.digShow = true;
    },
    //设置修改、添加
    handle_edit(row) {
      this.cuttData = { ...row };
      this.digShow = true;
    },
    //结果请求设置
    res_handle(res) {
      console.log('res '+JSON.stringify(res))
      this.digShow = this.lock = false;
      if (1 === res.status) {
        this.$message.success("操作成功！");
        this.init();
      } else {
        this.$message.error(res.msg);
      }
    },
    //删除
    handle_del(index, row) {
      this.$confirm("此操作将删除[" + row.name + "] 职位分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params={};
          params.id=row.id;
          CATEGORY.delete(params).then(this.res_handle);
        })
        .catch(() => {});
    },
    formatterStatus(row, column) {
      let status = row?.status;
      return status === 1 ? "上线" : status === 0 ? "下线" : "未知";
    },
    handle_clickenable(row) {
      this.$confirm("此操作将上线[" + row.name + "] 职位分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(()=>{
          let params={};
          params.id=row.id;
          CATEGORY.enable(params).then(this.res_handle);
        })
        .catch(() => {});
    },
    handle_clickdisable(row) {
      this.$confirm("此操作将下线[" + row.name + "] 职位分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(()=>{
          let params={};
          params.id=row.id;
          CATEGORY.disable(params).then(this.res_handle);
        })
        .catch((err) => {
          console.error('Error',err)
        });
    },
  },
};
</script>

<style scoped>
.search {
  margin-bottom: 10px;
}
.search_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.search_buttons input {
  margin-right: 20px;
}
.search_buttons input:last-child {
  margin-right: 0;
}
.pageHeader {
  background-color: white;
  border-bottom: 1px solid #cccccc;
  font-size: 18px;
  padding-bottom: 5px;
  padding-top: 5px;
  /*padding: 5px 10px;*/
}

.red {
  color: #ff0000;
}
</style>